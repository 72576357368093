import {useState} from "react";
import {useAuthHeader} from "react-auth-kit";
import {getRequest} from "../../../requests";

export const useCalendlyApp = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('')

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const connect = async (accountId: number, step?: number, siteId?: string, url?: string | null) => {
    setLoading(true);
    setLoadingMessage('Connection')
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'CalendlyApp.auth',
          accountId,
          step,
          siteId,
          url,
          token: token,
          format: 'json'
        }
      })

      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          setLoadingMessage('');
          return;
        }
        setLoading(false);
        setLoadingMessage('');
        return response.data;
      }
    } catch (error) {
      setErrorMessage('Error adding website');
      setLoading(false);
      setLoadingMessage('')
    }
  }

  const disconnect = async (accountId: number) => {
    setLoading(true);
    setLoadingMessage('Disconnection')
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'CalendlyApp.disconnect',
          accountId,
          token: token,
          format: 'json'
        }
      })

      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          setLoadingMessage('');
          return;
        }
        setLoading(false);
        setLoadingMessage('');
        return response;
      }
    } catch (error) {
      setErrorMessage('Error adding website');
      setLoading(false);
      setLoadingMessage('');
    }
  }

  return { connect, disconnect, loading, loadingMessage, errorMessage };
}