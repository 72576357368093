import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { IPlan, IPlanInterval } from '../../../interfaces/components';
import { usePayment } from '../../../pages/main/Pricing/usePayment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import SuccessMessage from '../../../components/global/ui/SuccessMessage';
import Error from '../../../components/global/ui/alerts/Error';
import { useAuthHeader, useAuthUser, useSignIn } from 'react-auth-kit';
import { AMPLITUDE_EVENTS, ORGANIZATION_ID, SUBSCRIPTION_ID_KEY } from '../../../constants';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';
import { trackAmplitudeEvent } from '../../../util/amplitudeService';
declare global {
  interface Window {
    dataLayer: any[];
  }
}
export default function FirstTimeCheckoutForm({
  plan,
  interval
}: {
  plan: IPlan;
  interval: IPlanInterval;
}) {
  const { addPaymentCard, confirmPayment, makePayment, abandonCart, errorMessage, loading } =
    usePayment();
  const [message, setMessage] = useState<string | null>(null);

  const navigate = useNavigate();
  const signIn = useSignIn();

  const auth = useAuthUser();
  const authHeader = useAuthHeader();
  const { fetchOrganizationData, profile } = useContext(OrganizationContext);

  const [tokenType, token] = authHeader().split(' ');

  const [error, setError] = useState<string | null | undefined>(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter a valid email').required('Enter your email'),
    coupon: Yup.string(),
    number: Yup.string()
      .max(16)
      .min(14, 'Card number must be at least 14 characters')
      .trim()
      .required('Enter your card number'),
    expMonth: Yup.string()
      .typeError('Expiry month must be digits 01 - 12')
      .max(2, 'Expiry month must be at most 2 characters')
      .min(1, 'Expiry month must be at least 1 character')
      .required('Enter expiry month'),
    expYear: Yup.string()
      .typeError('Expiry year must be digits')
      .max(2, 'Expiry year must be at most 2 characters')
      .min(2, 'Expiry year must be at least 2 characters')
      .required('Enter expiry year'),
    name: Yup.string().required('Name on card is required'),
    cvc: Yup.string().max(4).required('CVC is required')
  });

  const formik = useFormik({
    initialValues: {
      plan: plan && plan.value,
      name: '',
      coupon: '',
      interval: interval && interval.value,
      email: auth() && auth()?.email,
      number: '',
      expMonth: '',
      expYear: '',
      cvc: '',
      addressOne: '',
      addressTwo: '',
      city: '',
      country: ''
    },
    validationSchema,
    onSubmit: async (values) => handleNewSubscription(values)
  });

  // useEffect(() => {
  //   const handleBeforeUnload = async (event: any) => {
  //     // Perform actions before the component unloads
  //     event.preventDefault();
  //     // event.returnValue = '';
  //     await abandonCart({ plan: plan.value });
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  const handleNewSubscription = async (values: FormikValues) => {
    setMessage(null);
    setError(null);

    const addCardResponse = await addPaymentCard({
      name: values.name,
      number: values.number,
      expMonth: values.expMonth,
      expYear: values.expYear,
      cvc: values.cvc,
      addressOne: values.addressOne,
      addressTwo: values.addressTwo,
      country: values.country,
      city: values.city,
      isNewCustomer: true
    });

    if (addCardResponse && addCardResponse.card_token) {
      // register event for card added to account for the first time
      trackAmplitudeEvent(AMPLITUDE_EVENTS.CARD_ADDED_TO_ACCOUNT);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'registration_success'
      });

      const createCustomerResponse = await makePayment({
        name: values.name,
        email: values.email,
        plan: values.plan,
        interval: values.interval,
        coupon: values.coupon,
        cardToken: addCardResponse.card_token
      });

      if (createCustomerResponse) {
        const { subscriptionId } = createCustomerResponse.msg;
        if (addCardResponse?.result === 'success') {
          const confirmation = await confirmPayment(subscriptionId, 'no');
          if (confirmation.result === 'success') {
            setMessage(confirmation.msg);

            // save subscription ID to local storage to be used on the setup page
            localStorage.setItem(SUBSCRIPTION_ID_KEY, subscriptionId);
            localStorage.removeItem('plan');

            // save organization
            localStorage.setItem(
              ORGANIZATION_ID,
              profile && (profile.organizations[0].account_id as string)
            );

            signIn({
              token,
              tokenType: 'Bearer',
              expiresIn: 720,
              authState: {
                ...auth(),
                newUser: false
              }
            });
            setTimeout(() => {
              // refetch organization data
              fetchOrganizationData();
              navigate(routes.SETUP);
            }, 1000);
          }
        }
      }
    }
  };

  return (
    <>
      {message ? (
        <div className={'h-[25vh] flex flex-col justify-center items-center text-center'}>
          <h4 className={'text-[#008160] text-lg'}>{message}</h4>
          <p className={'text-sm text-gray-400'}>Redirecting to dashboard...</p>
        </div>
      ) : loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Creating a new subscription...Please wait</h5>
        </div>
      ) : (
        <div>
          <div className={' grid gap-2'}>
            <div>
              <div>
                {message ? (
                  <SuccessMessage message={message} />
                ) : (
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      formik.handleSubmit();
                    }}
                    id={'first_time_payment_card-form'}
                    className={'grid gap-2'}>
                    <div>
                      <label className={'text-sm'}>Name on card</label>
                      <input
                        type={'text'}
                        name={'name'}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        autoFocus={true}
                        className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1 flex items-center"
                      />
                      <p className={'text-sm text-red-500'}>
                        {formik.touched.name && formik.errors.name}
                      </p>
                    </div>
                    <div>
                      <label className={'text-sm'}>Card details</label>
                      <div
                        className={'mt-1 border border-gray-300 rounded grid grid-cols-12 gap-2'}>
                        <div className={'col-span-7 flex items-center gap-2'}>
                          <span className={'pl-2 pt-1'}>
                            <i className="fi fi-ss-credit-card text-xl text-gray-300"></i>
                          </span>
                          <input
                            type={'text'}
                            name={'number'}
                            maxLength={16}
                            minLength={15}
                            value={formik.values.number}
                            onChange={formik.handleChange}
                            placeholder={'Card number'}
                            className={'outline-0 w-full p-2'}
                          />
                        </div>
                        <div className={'col-span-3 flex items-center'}>
                          <input
                            type={'text'}
                            name={'expMonth'}
                            value={formik.values.expMonth}
                            onChange={formik.handleChange}
                            placeholder={'MM'}
                            minLength={2}
                            maxLength={2}
                            className={'outline-0 w-full p-2 text-center'}
                          />
                          {'/'}
                          <input
                            type={'text'}
                            name={'expYear'}
                            minLength={2}
                            maxLength={2}
                            placeholder={'YY'}
                            value={formik.values.expYear}
                            onChange={formik.handleChange}
                            className={'outline-0 w-full p-2 text-center'}
                          />
                        </div>
                        <div className={'col-span-2'}>
                          <input
                            type={'text'}
                            name={'cvc'}
                            minLength={3}
                            maxLength={
                              formik.values.number.length === 14 ||
                              formik.values.number.length === 16
                                ? 3
                                : 4
                            }
                            value={formik.values.cvc}
                            onChange={formik.handleChange}
                            placeholder={'CVC'}
                            className={'outline-0 w-full p-2 text-center'}
                          />
                        </div>
                      </div>
                      <p className={'text-sm text-red-500'}>
                        {(formik.touched.number && formik.errors.number) ||
                          (formik.touched.expMonth && formik.errors.expMonth) ||
                          (formik.touched.expMonth && formik.errors.expMonth) ||
                          (formik.touched.cvc && formik.errors.cvc)}
                      </p>
                    </div>

                    <div>
                      <label className={'text-sm'}>
                        Email <span className={'text-red-500'}>*</span>{' '}
                      </label>
                      <input
                        type={'email'}
                        className={'p-2 w-full rounded border border-gray-300'}
                        name={'email'}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />

                      <p className={'text-sm text-red-500'}>
                        {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                        {/*@ts-ignore*/}
                        {formik.touched.email && formik.errors.email}
                      </p>
                    </div>

                    <div>
                      <label className={'text-sm'}>Coupon</label>
                      <input
                        type={'text'}
                        className={'p-2 w-full rounded border border-gray-300'}
                        name={'coupon'}
                        value={formik.values.coupon}
                        onChange={formik.handleChange}
                      />
                      <small>Use promo code</small>
                    </div>
                    <div className={'mt-2'}>
                      <PrimaryButton
                        disabled={loading}
                        title={loading ? 'creating Subscription...' : 'Proceed to subscribe'}
                        handler={() => formik.handleSubmit()}
                      />
                    </div>

                    {errorMessage && <Error value={errorMessage} />}
                    {error && <Error value={error} />}
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
