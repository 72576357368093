import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import SuccessMessage from '../../components/global/ui/SuccessMessage';
import Modal from '../../components/global/ui/Modal';
import { IWebsiteDeleteModal } from '../../interfaces/website';
import Snippet from '../SnippetInstallation/Snippet';
import { useAddWebsite } from '../../pages/main/Setup/useAddWebsite';

export default function ViewSnippet({ website, closeModal }: IWebsiteDeleteModal) {
  const { generateCode, loading } = useAddWebsite();

  const [message, setMessage] = useState<string | null>(null);

  // State variables to handle values for snippet verification
  const [trackingDetails, setTrackingDetails] = useState({
    script: 'null',
    orderStatusScript: 'null',
    postPurchaseScript: 'null',
    woocommerce: 'null',
    bigcommerce: 'null',
    conversion: 'null',
    platform: 'null'
  });

  const handleGenerateCode = async () => {
    return await generateCode(website.idsite);
  };

  useEffect(() => {
    handleGenerateCode().then((response) => {
      setTrackingDetails({
        script: response?.data.tracking.script,
        orderStatusScript: response?.data.tracking.orderstatus,
        postPurchaseScript: response?.data.tracking.postpurchase,
        woocommerce: response?.data.tracking.woocommerce,
        bigcommerce: response?.data.tracking.bigcommerce,
        conversion: response?.data.tracking.conversion,
        platform: website.platform
      });
    });
  }, []);

  return (
    <Modal
      closeModal={closeModal}
      title={'Verification Snippet'}
      width={'w-full md:w-4/5 lg:w-3/5'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
        </div>
      ) : (
        <div>
          {message ? (
            <SuccessMessage message={message} />
          ) : (
            <div className="mb-3">
              <div className={'grid gap-3'}>
                {(trackingDetails.platform == 'woocommerce' ||
                  trackingDetails.platform == 'wordpress') && (
                  <>
                    <div>
                      <h6 className={'font-semibold text-sm text-gray-700'}>
                        Installation for {trackingDetails.platform} website
                      </h6>
                    </div>
                    <div>
                      <h6 className={'text-sm'}>Download this zip file</h6>
                      <div className={'flex'}>
                        <a
                          href={trackingDetails.woocommerce}
                          download={true}
                          className={`px-4 py-2 hover:bg-emerald-600 rounded text-white bg-[#008160] text-sm flex gap-2`}>
                          <span>
                            <i className="fi fi-rr-download text-sm"></i>
                          </span>
                          <span>Download</span>
                        </a>
                      </div>
                    </div>
                  </>
                )}
                {(trackingDetails.platform == 'webflow' ||
                  trackingDetails.platform == 'squarespace') && (
                  <>
                    <div>
                      <h6 className={'font-semibold text-sm text-gray-700'}>
                        Installation for your {trackingDetails.platform} website
                      </h6>
                    </div>
                    <div>
                      <h6 className={'text-sm'}>
                        Copy and paste this snippet inside the header of your pages
                      </h6>
                      <Snippet snippet={trackingDetails.script as string} />
                    </div>
                  </>
                )}
                {trackingDetails.platform == 'other' && (
                  <>
                    <div>
                      <h6 className={'font-semibold text-sm text-gray-700'}>
                        Installation for your platform
                      </h6>
                    </div>
                    <div>
                      <h6 className={'text-sm'}>
                        1. Copy and paste this snippet inside the header of your pages
                      </h6>
                      <Snippet snippet={trackingDetails.script as string} />
                    </div>
                    <div>
                      <h6 className={'font-medium text-sm'}>
                        2. Copy and paste this snippet in your conversion page
                      </h6>
                      <Snippet snippet={trackingDetails.conversion as string} />
                    </div>
                  </>
                )}
                {trackingDetails.platform == 'bigcommerce' && (
                  <div className={'lg:h-[55vh] overflow-auto slim-scroll pr-2 grid gap-2'}>
                    <div>
                      <h6 className={'font-semibold text-lg text-gray-700'}>
                        To add the snippet to your {trackingDetails.platform} website
                      </h6>
                      <h6 className={'text-sm my-3'}>
                        **PLEASE NOTE**: there are two snippets you need to install on your
                        BigCommerce website to get revenue tracking included in your heatmap.
                      </h6>
                    </div>
                    <div>
                      <h6 className={'font-medium text-sm mb-2'}>
                        To add the snippet to your website:
                      </h6>
                      <h6 className={'font-medium text-sm'}>
                        Copy the Header snippet after adding your site
                      </h6>
                      <Snippet snippet={trackingDetails.script as string} />
                    </div>
                    <div className={'mt-4'}>
                      <h6>Add your conversion snippet (Second Snippet)</h6>
                    </div>
                    <div>
                      <Snippet snippet={trackingDetails.bigcommerce as string} />
                    </div>
                  </div>
                )}
                {trackingDetails.platform == 'shopify' && (
                  <>
                    <div>
                      <h6 className={'font-semibold text-sm text-gray-700'}>
                        Installation for your shopify website
                      </h6>
                    </div>
                    <div>
                      <h6 className={'font-medium text-sm'}>1. Code snippet for theme.liquid & 3rd Party Page Builders</h6>
                      <Snippet snippet={trackingDetails.script as string} />
                    </div>

                    <div>
                      <h6 className={'font-medium text-sm'}>2. Code snippet for Custom Pixel</h6>
                      <Snippet snippet={trackingDetails.postPurchaseScript as string} />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}
