import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import EditProfile from '../../../feature/AccountProfile/EditProfile';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import Layout from '../../../layouts/global/Layout';
import { IUserProfile } from '../../../interfaces/user';
import { routes } from '../../../constants/routes';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import EnableTwoFASetup from '../../../feature/AccountProfile/EnableTwoFASetup';
import DisableTwoFA from '../../../feature/AccountProfile/DisableTwoFA';
import PageTitle from '../../../components/global/PageTitle';
import {useCalendlyApp} from "./useCalendlyApp";
import {useUserSites} from "../../../hooks/useUserSites";

export default function AccountProfile() {
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [twoFAOpen, setTwoFAOpen] = useState<boolean>(false);
  const [confirmDisableTwoFAOpen, setConfirmDisableTwoFAOpen] = useState<boolean>(false);
  const [profileLoading, setProfileLoading] = useState(false)

  const { profile, loading, setProfile } = useContext(OrganizationContext);

  const { connect, disconnect, loading: calendlyLoading, loadingMessage, errorMessage } = useCalendlyApp();
  const { loadUserProfile } = useUserSites();

  const handleConnection = async (accountId: number | undefined) => {
    if (accountId) {
      const response = await connect(accountId)

      if (response?.result === 'success') {
        window.location.href = response.calendlyAuthUrl
      }
      setProfileLoading(true)
      const profileResponse = await loadUserProfile({
        account_id: accountId
      });

      setProfile(profileResponse)
      setProfileLoading(false)
    }
  }

  const handleDisconnect = async (accountId: number | undefined) => {
    if (accountId) {
      const response = await disconnect(accountId)

      setProfileLoading(true)
      const profileResponse = await loadUserProfile({
        account_id: accountId
      });

      setProfile(profileResponse)
      setProfileLoading(false)
    }
  }


  console.log(profile)
  return (
    <Layout >
      <PageTitle title={'Account Settings'} />

      <div className={'lg:col-span-10 grid lg:grid-cols-10 gap-5'}>
        {editOpen && (
          <EditProfile
            user={profile.profile_details as IUserProfile}
            closeModal={() => setEditOpen(false)}
          />
        )}
        {twoFAOpen && (
          <EnableTwoFASetup
            user={profile.profile_details as IUserProfile}
            closeModal={() => setTwoFAOpen(false)}
          />
        )}
        {confirmDisableTwoFAOpen && (
          <DisableTwoFA closeModal={() => setConfirmDisableTwoFAOpen(false)} />
        )}
        <div className={'lg:col-span-7'}>
          {loading  ? (
            <div className={'flex h-[60vh] gap-3 flex-col justify-center items-center text-center'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <p>Loading profile details</p>
            </div>
          ) : (
            <div className={'rounded-md border border-gray-200 bg-white p-3 md:p-5 text-gray-500'}>
              <div className={'flex justify-between gap-5'}>
                <h6 className={'font-semibold md:font-medium text-sm lg:text-base'}>
                  Profile Details
                </h6>
                <button
                  type={'button'}
                  onClick={() => setEditOpen(true)}
                  className={
                    'rounded bg-[#008160] hover:bg-emerald-600 text-white font-medium px-3 md:px-4 py-2 md:py-2 text-xs md:text-sm flex gap-2'
                  }>
                  <span>
                    <i className="fi fi-rr-pencil"></i>
                  </span>
                  <span>Edit</span>
                </button>
              </div>
              {/*<hr className={'my-2 md:my-4'} />*/}
              <div className={'my-2 w-full grid gap-5'}>
                <div className={'grid gap-3 md:gap-5'}>
                  <div
                    className={
                      'w-12 lg:w-20 h-12 lg:h-20 bg-emerald-100 text-emerald-600 font-medium rounded-full flex justify-center items-center'
                    }>
                    <span className={'font-medium text-lg md:text-3xl uppercase'}>
                      {profile.profile_details?.full_name?.charAt(0)}
                    </span>
                  </div>

                  <div className={'grid md:grid-cols-2 gap-4'}>
                    <div className={'lg:col-span-2 grid gap-1'}>
                      <span className={'text-sm md:text-sm font-medium'}>Full name</span>
                      <ProfileField value={profile.profile_details?.full_name as string} />
                    </div>
                    <div className={'grid gap-1'}>
                      <span className={'text-sm md:text-sm font-medium'}>Username</span>
                      <ProfileField value={profile.profile_details?.username as string} />
                    </div>
                    <div className={'grid gap-1'}>
                      <span className={'text-sm md:text-sm font-medium'}>Email address</span>
                      <ProfileField value={profile.profile_details?.email as string} />
                    </div>
                    <div className={'grid gap-1'}>
                      <span className={'text-sm md:text-sm font-medium'}>Phone number</span>
                      <ProfileField
                        value={
                          profile.profile_details?.phone ? profile.profile_details?.phone : 'N/A'
                        }
                      />
                    </div>
                    <div className={'grid gap-1'}>
                      <span className={'text-sm md:text-sm font-medium'}>Billing address</span>
                      <ProfileField
                        value={
                          profile.profile_details?.billing_address
                            ? profile.profile_details?.billing_address
                            : 'N/A'
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={'lg:col-span-3 flex flex-col gap-5'}>
          <div className={'rounded-md border border-gray-200 bg-white p-3 md:p-5 text-gray-500'}>
            <h6 className={'font-semibold md:font-medium text-sm lg:text-base'}>
              Account Security
            </h6>
            <hr className={'my-1 md:my-2'}/>
            <div className={'my-2'}>
              <h6 className={'text-sm font-medium mb-'}>Set up Multi-Factor Authentication</h6>
              <p className={'text-sm md:text-sm'}>
                Each time you sign in to your Heatmap account, you will need your password and also
                verify with an Authenticator app.
              </p>
            </div>
            <div className={'flex mt-3'}>
              {profile && profile.profile_details?.two_factor_setup == 1 ? (
                <button
                  type={'button'}
                  onClick={() => setConfirmDisableTwoFAOpen(true)}
                  className={
                    'rounded border border-red-500 hover:bg-red-100 text-red-500 font-medium px-3 md:px-4 py-2 md:py-2 text-xs md:text-sm'
                  }>
                  Disable 2FA
                </button>
              ) : (
                <button
                  type={'button'}
                  onClick={() => setTwoFAOpen(true)}
                  className={
                    'rounded bg-[#008160] hover:bg-emerald-600 text-white font-medium px-3 md:px-4 py-2 md:py-2 text-xs md:text-sm'
                  }>
                  Enable 2FA
                </button>
              )}
            </div>
          </div>
          <div className={'rounded-md border border-gray-200 bg-white p-3 md:p-5 text-gray-500'}>
            <h6 className={'font-semibold md:font-medium text-sm lg:text-base'}>Change Password</h6>
            <hr className={'my-1 md:my-2'}/>
            <div className={'my-2'}>
              <p className={'text-sm md:text-sm'}>
                To reset your password, please verify your account by providing an OTP that will be
                sent to the email associated with your account.
              </p>
            </div>
            <div className={'flex mt-3'}>
              <Link
                to={routes.RESET_PASSWORD}
                className={
                  'rounded bg-[#008160] hover:bg-emerald-600 text-white font-medium px-3 md:px-4 py-2 md:py-2 text-xs md:text-sm'
                }>
                Reset password
              </Link>
            </div>
          </div>


          <div className={'rounded-md border border-gray-200 bg-white p-3 md:p-5 text-gray-500'}>
            <h6 className={'font-semibold md:font-medium text-sm lg:text-base'}>Connection to Calendly App</h6>
            <hr className={'my-1 md:my-2'}/>
            <div className={'my-2'}>
              <p className={'text-sm md:text-sm'}>
                To connect/disconnect to Calendly App, please click on the button below and follow the instructions.
              </p>
            </div>
            <div className={'flex mt-3'}>
              {profile && profile.profile_details?.has_calendly_connection ? (
                <button
                  type={'button'}
                  onClick={() => handleDisconnect(profile.profile_details?.user_id)}
                  disabled={calendlyLoading || profileLoading}
                  className={
                    'rounded border border-red-500 disabled:border-gray-300 disabled:text-gray-300 disabled:hover:bg-gray-100 hover:bg-red-100 text-red-500 font-medium px-3 md:px-4 py-2 md:py-2 text-xs md:text-sm'
                  }>
                  {calendlyLoading || profileLoading ? (
                    <div className={'flex flex-row justify-center items-center text-center'}>
                      <LoadingSpinner color={'text-[#008160]'} size={'small'}/>
                      <p className={'ml-1'}>{loadingMessage || 'Disconnection'}</p>
                    </div>
                  ) : 'Disconnect Calendly App'}
                </button>
              ) : (
                <button
                  type={'button'}
                  disabled={calendlyLoading || profileLoading}
                  onClick={() => handleConnection(profile.profile_details?.user_id)}
                  className={
                    'rounded bg-[#008160] disabled:border-gray-300 disabled:bg-gray-100 disabled:text-gray-300 disabled:hover:bg-gray-100 hover:bg-emerald-600 text-white font-medium px-3 md:px-4 py-2 md:py-2 text-xs md:text-sm'
                  }>
                  {calendlyLoading || profileLoading ? (
                    <div className={'flex flex-row justify-center items-center text-center'}>
                      <LoadingSpinner color={'text-[#008160]'} size={'small'}/>
                      <p className={'ml-1'}>{loadingMessage || 'Connection'}</p>
                    </div>
                  ) : 'Connect Calendly App'}
                </button>
              )}
            </div>
          </div>

        </div>
      </div>
    </Layout>
  );
}

function ProfileField({value}: { value: string }) {
  return (
    <span className={'p-2 rounded border border-gray-300 cursor-default'}>
      <span className={'text-sm md:text-base text-gray-700'}>{value}</span>
    </span>
  );
}
