import React, { useEffect, useRef } from 'react';
import './App.css';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Login from './pages/authentication/Login';
import ResetPassword from './pages/authentication/ResetPassword';
import Register from './pages/authentication/Register';
import Setup from './pages/main/Setup';
import Dashboard from './pages/main/Dashboard';
import VerifyEmail from './pages/authentication/VerifyEmail';
import NewPassword from './pages/authentication/NewPassword';
import ProtectedRoute from './routes/ProtectedRoute';
import VerifyEmailForPassword from './pages/authentication/VerifyEmailForPassword';
import Pricing from './pages/main/Pricing';
import AccountProfile from './pages/main/AccountProfile';
import AccountSubscription from './pages/main/AccountSubscription';
import Websites from './pages/main/Websites';
import Notifications from './pages/main/Notifications';
import TeamManagement from './pages/main/TeamManagement';
import OpenAIDocuments from './pages/main/OpenAIDocuments/index';
import Plans from './pages/main/Plans';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import SupportTickets from './pages/main/SupportTickets';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Coupons from './pages/main/Coupons';
import NotFound from './pages/main/NotFound';
import Clients from './pages/main/Clients';
import UserSubscriptions from './pages/main/UserSubscriptions';
import Logout from './pages/main/Logout';
import { GOOGLE_RECAPTCHA_OPTIONS, GOOGLE_TAG, INTERCOM_APP_ID } from './constants';
import { routes } from './constants/routes';
import AccountHolderActivation from './pages/authentication/AccountHolderActivation';
import Accounts from './pages/main/Accounts';
import LandingPageAuth from './pages/authentication/LandingPageAuth';
import Feedback from './pages/main/Feedback';
import Logs from './pages/main/Logs';
import PlatformSettings from './pages/main/PlatformSettings';
import NoRecaptchaLogin from './pages/authentication/NoRecaptchaLogin';
import { IntercomProvider } from 'react-use-intercom';
import { QueryClient, QueryClientProvider } from 'react-query';
import OrganizationContextProvider from './contexts/OrganizationContext';
import UnsubscribeEmail from './pages/authentication/UnsubscribeEmail';
import { initAmplitude } from './util/amplitudeService';
import ReactGA from 'react-ga4';

function App() {
  const auth = useAuthUser();
  const authHeader = useAuthHeader();
  const queryClient = new QueryClient();
  const ref = useRef(false);

  initAmplitude();

  const [tokenType, token] = authHeader().split(' ');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname == routes.MAIN) navigate(routes.DASHBOARD, { replace: true });
  }, []);

  // useEffect(() => {
  //   if (process.env.NODE_ENV == 'production' && !ref.current && typeof window !== 'undefined') {
  //     ReactGA.initialize(GOOGLE_TAG);
  //     ref.current = true;
  //   }
  // }, []);

  return (
    <IntercomProvider autoBoot={true} appId={INTERCOM_APP_ID}>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route
            path={routes.NO_RECAPTCHA_LOGIN}
            element={
              token && auth()?.super_user == 1 ? (
                <Navigate to={routes.DASHBOARD} />
              ) : token && auth()?.newUser ? (
                <Navigate to={routes.PLANS} />
              ) : token && auth() ? (
                <Navigate to={routes.DASHBOARD} />
              ) : (
                <NoRecaptchaLogin />
              )
            }
          />

          <Route
            path={routes.RESET_PASSWORD}
            element={
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTURE_SITE_KEY as string}
                scriptProps={GOOGLE_RECAPTCHA_OPTIONS}>
                <ResetPassword />
              </GoogleReCaptchaProvider>
            }
          />
          <Route
            path={routes.REGISTER}
            element={
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTURE_SITE_KEY as string}
                scriptProps={GOOGLE_RECAPTCHA_OPTIONS}>
                <Register />
              </GoogleReCaptchaProvider>
            }
          />
          <Route
            path={routes.LOGIN}
            element={
              token && auth()?.super_user == 1 ? (
                <Navigate to={routes.DASHBOARD} />
              ) : token && auth()?.newUser ? (
                <Navigate to={routes.PLANS} />
              ) : token && auth() ? (
                <Navigate to={routes.DASHBOARD} />
              ) : (
                <GoogleReCaptchaProvider
                  reCaptchaKey={process.env.REACT_APP_RECAPTURE_SITE_KEY as string}
                  scriptProps={GOOGLE_RECAPTCHA_OPTIONS}>
                  <Login />
                </GoogleReCaptchaProvider>
              )
            }
          />
          <Route
            path={routes.VALIDATE_ACCOUNT}
            element={
              <OrganizationContextProvider>
                <AccountHolderActivation />
              </OrganizationContextProvider>
            }
          />
          <Route path={routes.AUTHENTICATE_LANDING_PAGE_LOGIN} element={<LandingPageAuth />} />
          <Route path={routes.UNSUBSCRIBE_EMAIL} element={<UnsubscribeEmail />} />
          <Route path={routes.VERIFY_EMAIL} element={<VerifyEmail />} />
          <Route path={routes.VERIFY_EMAIL_FOR_PASSWORD} element={<VerifyEmailForPassword />} />
          <Route path={routes.NEW_PASSWORD} element={<NewPassword />} />

          <Route
            path={routes.DASHBOARD}
            element={
              <ProtectedRoute loginPath={routes.LOGIN}>
                <OrganizationContextProvider>
                  <Dashboard />
                </OrganizationContextProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.LOGOUT}
            element={
              <ProtectedRoute loginPath={routes.LOGIN}>
                <Logout />
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.PLANS}
            element={
              auth()?.super_user == 1 ? (
                <NotFound />
              ) : (
                <ProtectedRoute loginPath={routes.LOGIN}>
                  <OrganizationContextProvider>
                    <Plans />
                  </OrganizationContextProvider>
                </ProtectedRoute>
              )
            }
          />
          <Route
            path={routes.FEEDBACK}
            element={
              auth()?.super_user == 0 ? (
                <NotFound />
              ) : (
                <ProtectedRoute loginPath={routes.LOGIN}>
                  <OrganizationContextProvider>
                    <Feedback />
                  </OrganizationContextProvider>
                </ProtectedRoute>
              )
            }
          />
          <Route
            path={routes.LOGS}
            element={
              auth()?.super_user == 0 ? (
                <NotFound />
              ) : (
                <ProtectedRoute loginPath={routes.LOGIN}>
                  <OrganizationContextProvider>
                    <Logs />
                  </OrganizationContextProvider>
                </ProtectedRoute>
              )
            }
          />
          <Route
            path={routes.PRICING}
            element={
              auth()?.super_user == 1 ? (
                <NotFound />
              ) : (
                <ProtectedRoute loginPath={routes.LOGIN}>
                  <OrganizationContextProvider>
                    <Pricing />
                  </OrganizationContextProvider>
                </ProtectedRoute>
              )
            }
          />
          <Route
            path={routes.ACCOUNTS}
            element={
              <ProtectedRoute loginPath={routes.LOGIN}>
                <OrganizationContextProvider>
                  <Accounts />
                </OrganizationContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.SETUP}
            element={
              <ProtectedRoute loginPath={routes.LOGIN}>
                <OrganizationContextProvider>
                  <Setup />
                </OrganizationContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.WEBSITES}
            element={
              <ProtectedRoute loginPath={routes.LOGIN}>
                <OrganizationContextProvider>
                  <Websites />
                </OrganizationContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.NOTIFICATIONS}
            element={
              <ProtectedRoute loginPath={routes.LOGIN}>
                <OrganizationContextProvider>
                  <Notifications />
                </OrganizationContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={routes.SUPPORT}
            element={
              <ProtectedRoute loginPath={routes.LOGIN}>
                <OrganizationContextProvider>
                  <SupportTickets />
                </OrganizationContextProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.COUPONS}
            element={
              auth()?.super_user == 0 ? (
                <NotFound />
              ) : (
                <ProtectedRoute loginPath={routes.LOGIN}>
                  <OrganizationContextProvider>
                    <Coupons />
                  </OrganizationContextProvider>
                </ProtectedRoute>
              )
            }
          />
          <Route
            path={routes.SUBSCRIPTIONS}
            element={
              auth()?.super_user == 0 ? (
                <NotFound />
              ) : (
                <ProtectedRoute loginPath={routes.LOGIN}>
                  <OrganizationContextProvider>
                    <UserSubscriptions />
                  </OrganizationContextProvider>
                </ProtectedRoute>
              )
            }
          />
          <Route
            path={routes.CLIENTS}
            element={
              auth()?.super_user == 0 ? (
                <NotFound />
              ) : (
                <ProtectedRoute loginPath={routes.LOGIN}>
                  <OrganizationContextProvider>
                    <Clients />
                  </OrganizationContextProvider>
                </ProtectedRoute>
              )
            }
          />
          <Route
            path={routes.PLATFORM_SETTINGS}
            element={
              auth()?.super_user == 0 ? (
                <NotFound />
              ) : (
                <ProtectedRoute loginPath={routes.LOGIN}>
                  <OrganizationContextProvider>
                    <PlatformSettings />
                  </OrganizationContextProvider>
                </ProtectedRoute>
              )
            }
          />
          <Route path={'/account'}>
            <Route
              path={routes.ACCOUNT_PROFILE}
              element={
                <ProtectedRoute loginPath={routes.LOGIN}>
                  <OrganizationContextProvider>
                    <AccountProfile />
                  </OrganizationContextProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.ACCOUNT_PAYMENTS}
              element={
                <ProtectedRoute loginPath={routes.LOGIN}>
                  <OrganizationContextProvider>
                    <AccountSubscription />
                  </OrganizationContextProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path={routes.ACCOUNT_TEAM}
              element={
                <ProtectedRoute loginPath={routes.LOGIN}>
                  <OrganizationContextProvider>
                    <TeamManagement />
                  </OrganizationContextProvider>
                </ProtectedRoute>
              }
            />
</Route>
          <Route >
            <Route
              path={routes.OPEN_AI_DOCUMENTS}
              element={
                auth()?.super_user === 0 ? (
                  <NotFound />
                ) : (
                  <ProtectedRoute loginPath={routes.LOGIN}>
                    <OrganizationContextProvider>
                      <OpenAIDocuments />
                    </OrganizationContextProvider>
                  </ProtectedRoute>
                )
              }
            />


          </Route>
          <Route path={routes.ERROR} element={<NotFound />} />
        </Routes>
      </QueryClientProvider>
    </IntercomProvider>
  );
}

export default App;
