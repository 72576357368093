import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import { useContext, useState } from 'react';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { usePayment } from '../../../pages/main/Pricing/usePayment';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import SuccessMessage from '../../../components/global/ui/SuccessMessage';
import Error from '../../../components/global/ui/alerts/Error';
import Modal from '../../../components/global/ui/Modal';
import { AMPLITUDE_EVENTS, ORGANIZATION_ID } from '../../../constants';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { useAuthUser } from 'react-auth-kit';
import { trackAmplitudeEvent } from '../../../util/amplitudeService';

export default function AddPaymentCard({
  closeModal,
  customerId
}: {
  closeModal: () => void;
  customerId: string;
}) {
  const { addPaymentCard, loading, errorMessage } = usePayment();

  const validationSchema = Yup.object().shape({
    number: Yup.string()
      .max(16)
      .min(14, 'Card number must be at least 14 characters')
      .trim()
      .required('Enter your card number'),
    expMonth: Yup.string()
      .typeError('Expiry month must be digits 01 - 12')
      .max(2, 'Expiry month must be at most 2 characters')
      .min(1, 'Expiry month must be at least 1 character')
      .required('Enter expiry month'),
    expYear: Yup.string()
      .typeError('Expiry year must be digits')
      .max(2, 'Expiry year must be at most 2 characters')
      .min(2, 'Expiry year must be at least 2 characters')
      .required('Enter expiry year'),
    name: Yup.string().required('Name on card is required'),
    cvc: Yup.string().max(4).required('CVC is required')
  });
  const [message, setMessage] = useState<string | null>(null);
  const { fetchOrganizationData } = useContext(OrganizationContext);

  const auth = useAuthUser();

  const formik = useFormik({
    initialValues: {
      name: '',
      number: '',
      expMonth: '',
      expYear: '',
      cvc: '',
      addressOne: '',
      addressTwo: '',
      city: '',
      country: ''
    },
    validationSchema,
    onSubmit: async (values) => handleAddCard(values)
  });

  const handleAddCard = async (values: FormikValues) => {
    setMessage(null);
    const response = await addPaymentCard({
      name: values.name,
      number: values.number,
      expMonth: values.expMonth,
      expYear: values.expYear,
      cvc: values.cvc,
      addressOne: values.addressOne,
      addressTwo: values.addressTwo,
      country: values.country,
      city: values.city,
      customerId: customerId,
      account_id: (localStorage.getItem(ORGANIZATION_ID) as string) || auth()?.account_id
    });
    if (response?.result === 'success') {
      setMessage(response.msg);
      // register event for card added to account for the first time
      trackAmplitudeEvent(AMPLITUDE_EVENTS.CARD_ADDED_TO_ACCOUNT);
      // refetch organization data
      fetchOrganizationData(
        (localStorage.getItem(ORGANIZATION_ID) as string) || auth()?.account_id
      );

      setTimeout(() => {
        closeModal();
        // window.location.reload();
      }, 2000);
    }
  };

  return (
    <Modal closeModal={closeModal} title={'Add Payment Card'} width={'w-full md:w-1/2 lg:w-1/3'}>
      {loading ? (
        <div className={'flex flex-col justify-center items-center p-5'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <h5 className={'mt-2 text-sm'}>Adding a new card...Please wait</h5>
        </div>
      ) : (
        <div>
          <div>
            {message ? (
              <SuccessMessage message={message} />
            ) : (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  formik.handleSubmit();
                }}
                id={'new_payment_card-form'}
                className={'grid gap-2'}>
                <div>
                  <label className={'text-sm'}>Name on card</label>
                  <input
                    type={'text'}
                    name={'name'}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    autoFocus={true}
                    className="w-full bg-transparent outline-0 p-2 rounded border border-gray-300 mt-1"
                  />
                  <p className={'text-xs text-red-500'}>
                    {formik.touched.name && formik.errors.name}
                  </p>
                </div>

                <div>
                  <label className={'text-sm'}>Card details</label>
                  <div className={'mt-1 border border-gray-300 rounded grid grid-cols-12 gap-2'}>
                    <div className={'col-span-7 flex items-center '}>
                      <span className={'pl-2 pt-1'}>
                        <i className="fi fi-ss-credit-card text-xl text-gray-300"></i>
                      </span>
                      <input
                        type={'text'}
                        name={'number'}
                        maxLength={16}
                        minLength={15}
                        value={formik.values.number}
                        onChange={formik.handleChange}
                        placeholder={'Card number'}
                        className={'outline-0 w-full p-2 text-sm'}
                      />
                    </div>
                    <div className={'col-span-3 flex items-center'}>
                      <input
                        type={'text'}
                        name={'expMonth'}
                        value={formik.values.expMonth}
                        onChange={formik.handleChange}
                        placeholder={'MM'}
                        minLength={2}
                        maxLength={2}
                        className={'outline-0 w-full py-2 text-sm text-center'}
                      />
                      {'/'}
                      <input
                        type={'text'}
                        name={'expYear'}
                        minLength={2}
                        maxLength={2}
                        placeholder={'YY'}
                        value={formik.values.expYear}
                        onChange={formik.handleChange}
                        className={'outline-0 w-full py-2 text-sm text-center'}
                      />
                    </div>
                    <div className={'col-span-2'}>
                      <input
                        type={'text'}
                        name={'cvc'}
                        minLength={3}
                        maxLength={
                          formik.values.number.length === 14 || formik.values.number.length === 16
                            ? 3
                            : 4
                        }
                        value={formik.values.cvc}
                        onChange={formik.handleChange}
                        placeholder={'CVC'}
                        className={'outline-0 w-full p-2 text-sm text-center'}
                      />
                    </div>
                  </div>
                  <p className={'text-xs text-red-500'}>
                    {(formik.touched.number && formik.errors.number) ||
                      (formik.touched.expMonth && formik.errors.expMonth) ||
                      (formik.touched.expMonth && formik.errors.expMonth) ||
                      (formik.touched.cvc && formik.errors.cvc)}
                  </p>
                </div>

                <div className={'mt-2'}>
                  <PrimaryButton
                    disabled={loading}
                    title={loading ? 'Adding new card...' : 'Add card'}
                    handler={() => formik.handleSubmit()}
                  />
                </div>

                {errorMessage && <Error value={errorMessage} />}
              </form>
            )}
          </div>
        </div>
      )}
      <div className={'flex justify-center mt-2'}>
        <img src={'/assets/stripe.png'} alt={''} className={'w-full md:w-1/3 lg:w-1/2'} />
      </div>
    </Modal>
  );
}
